import React from 'react'
import { link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'


const MCStatus = () => (
<Layout>
<SEO title="MCStatus" keywords={[`Software`, `Production Environment`, `react`]} />

    <div className="BlueGradient">
        <div className="--headerClear"></div>
        <section className="--sectionPaceM --centerMargin --maxwidthS --edgePadding --centerTxt --heroAnimation  ">
            <h2><span>MCStatus</span> shows you laser information</h2>
            <p>Bring your business department and your shop floor together with at-a-glance laser reporting</p>
        </section>
    </div>
    <section className="--centerMargin --maxwidthL mcStatusImg">
    </section>
    <section className="--centerMargin --maxwidthL --edgePadding --sectionPace --grid6040">
      <article>
        <h2 className="DarkH">Handle unplanned production</h2>
        <p className="DarkP">See your overview for the work day and keep tabs on your shop. Our intelligent alert system brightly indicates when a machine has encountered an error, helping you decrease down-time and stay on track. With up to 3 lasers supported per display, true transparency has never looked so good.</p>
      </article>
      <div className="no_bullet">
                <img alt="Warning Icon" src={require('../images/_icons/Warning_Icon.png')}/>
                <p className="DarkP">Color coded laser status</p>
                <img alt="Clock Icon" src={require('../images/_icons/Clock_Icon.png')}/>
                <p className="DarkP">Shift Management</p>
                <img alt="Fast Icon" src={require('../images/_icons/Fast_Icon.png')}/>
                <p className="DarkP">Increased Awareness of Cell Efficiency</p>
                <img alt="Notification Icon" src={require('../images/_icons/Notification_icon.png')}/>
                <p className="DarkP">Collect Event Notifications</p>

            </div>    
        </section>
</Layout>
)

export default MCStatus